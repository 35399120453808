import React from 'react'
import { createRoot } from 'react-dom/client'
import "./styles.css";
import 'bootstrap/dist/css/bootstrap.css';
import 'react-datetime/css/react-datetime.css';

class TableComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      data: [],
      isLoaded: false,
    }
  }

  componentDidMount() {

  }

  componentDidUpdate(prevProps, prevState) {

  }

  render() {
    return (
      <>
        <div className="container">
          <div className="section">
            <h1 className="title">Streamline Your E-commerce Operations with InstantFBA</h1>
            <p>In e-commerce, efficient inventory management is key. InstantFBA offers tailored software solutions for 3PL providers and small merchants using Amazon's FBA service, focusing on simplifying your inventory and operational challenges.</p>

            <div className="section">
              <h2  className="header">For 3PL Providers: Optimized Operations</h2>
              <p>We provide 3PLs with tools that synchronize with every step of the supply chain. Real-time inventory tracking, warehouse operation efficiencies, and predictive analytics are all part of our package, enabling you to stay ahead and maintain control.</p>
            </div>

            <div className="section">
              <h2  className="header">For Amazon FBA Merchants: Clarity and Control</h2>
              <p>Our platform offers Amazon FBA merchants a clear and concise view of their inventory and operations. It's built to help you manage storage, reduce costs, and speed up fulfillment. With our software, you're equipped to handle the demands of selling on Amazon efficiently.</p>
            </div>

            <div className="section">
              <h2  className="header">Customizable and Scalable</h2>
              <p>Our solutions are not one-size-fits-all. We offer customization to meet the specific demands of your business, ensuring that as you grow, our software scales with you.</p>
            </div>

            <div className="section">
              <h2  className="header">Secure and Connected</h2>
              <p>We ensure our platform integrates smoothly with various services, maintaining a secure and centralized system for your business. Data protection is a cornerstone of our operations, giving you peace of mind.</p>
            </div>

            <div className="section">
              <p>Choose InstantFBA for software solutions that bring clarity and efficiency to your e-commerce business. Let's navigate the complexities of online retail together. Your inventory is our focus, ensuring you can concentrate on what you do best: selling and growing your business.</p>
            </div>
          </div>
        </div>
        <div className="contact-section">
          <h2>Get in Touch</h2>
          <p>Have questions or want to learn more about InstantFBA? Reach out to us at <a href="mailto:hello@instantfba.com">hello@instantfba.com</a></p>
        </div>
      </>
    )
  }
}

createRoot(document.getElementById('root')).render(<TableComponent />)
export default TableComponent
